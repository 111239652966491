<template>
    <div>
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Sedang memuat...
            </span>
        </div>
        
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="!dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Data siswa yang muncul adalah siswa dari sekolah <b>{{ dataSchool.name }}</b>.
            </span>
        </div>

        <div class="page-header" v-if="!dataFetchSchool">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Siswa</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <a href="javascript:void(0)" class="btn btn-primary rounded mr-2" data-toggle="modal" data-target="#modal_form_users"><i class="fas fa-plus"></i> Tambah Data</a>
                                <a href="javascript:void(0)" class="btn btn-outline-primary rounded" data-toggle="modal" data-target="#modal_form_generate"><i class="fas fa-qrcode"></i> Generate QR Code</a>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kelas</label>
                                    <select class="form-control" v-model="school_class" v-on:change="getData">
                                        <option value="">Semua Kelas</option>
                                        <option v-for="(item, index) in dataSchool.list_class" :key="index" :value="item">{{ item }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getByKeywords">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:35%">Nama Siswa</th>
                                                    <th style="width:20%">Email</th>
                                                    <th style="width:20%">Kelas</th>
                                                    <th style="width:5%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.fullname}}</td>
                                                    <td>{{item.email}}</td>
                                                    <td v-if="item.school_class">{{item.school_class}}</td>
                                                    <td v-if="!item.school_class"><small>Kelas belum diatur<br/><a href="javascript:void(0)" data-toggle="modal" data-target="#modal_form_users" v-on:click="editData(item)">Klik disini untuk mengatur kelas</a></small></td>
                                                    <td>
                                                        <a href="javascript:void(0)" class="btn btn-primary btn-sm rounded m-r-10" data-toggle="modal" data-target="#modal_form_users" v-on:click="editData(item)"><i class="fas fa-edit"></i> Ubah</a>
                                                        <!-- <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded "><i class="fas fa-trash"></i> Hapus</button> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>


                                <nav v-if="(!dataFetch && dataList.length > 0)" aria-label="navigation" class="m-t-20">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modal_form_users" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">{{form.id ? 'Ubah':'Tambah'}} Nama Siswa</div>
                            <div>
                                <button type="button" id="closeModal" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3 m-t-10">
                        <form v-on:submit.prevent="onSubmit">
                            <input type="hidden" class="form-control" id="" placeholder="Masukan Nama Siswa..." v-model="form.id" autocomplete="off">

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Email (<span class="text-danger">*</span>)</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Masukan Alamat Email..." v-model="form.email" autocomplete="off">

                                    <div class="alert alert-warning alert-dismissible fade show d-flex mt-2" role="alert">
                                        <strong><i class="fa fa-info-circle"></i></strong>
                                            <small class="m-l-10">
                                                Catatan<br/>
                                                <ul class="mb-0" style="margin-left:-20px !important;">
                                                    <li>Harap masukan alamat email yang benar.</li>
                                                    <li>Alamat email tidak boleh sama dengan akun siswa lain.</li>
                                                    <li>Alamat email akan digunakan sebagai username siswa untuk login.</li>
                                                </ul>
                                            </small>
                                    </div>

                                </div>
                            </div>
                            
                            <div class="form-group row" v-if="form.id == ''">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Password</label>
                                <div class="col-sm-9">
                                    <input type="password" class="form-control" id="inputEmail3" placeholder="Masukan Password Siswa..." v-model="form.password" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Siswa (<span class="text-danger">*</span>)</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Masukan Nama Siswa..." v-model="form.fullname" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Kelas (<span class="text-danger">*</span>)</label>
                                <div class="col-sm-9">
                                    <select class="form-control" v-model="form.school_class">
                                        <option value="">Pilih Kelas</option>
                                        <option v-for="(item, index) in dataSchool.list_class" :key="index" :value="item">{{ item }}</option>
                                    </select>                                    
                                </div>
                            </div>

                            <small>Keterangan:
                                <ul class="mb-0">
                                    <li>Tanda <span class="text-danger">(*)</span> Wajib Diisi</li>
                                    <li>Akun siswa yang telah ditambahkan tidak dapat dihapus, jika ingin menghapus akun siswa silahkan hubungin Admin.</li>
                                </ul>                                 
                            </small>
                            <hr/>
                            <button type="submit" class="btn btn-primary rounded btn-block" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                         </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modal_form_generate" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">Generate QR Code Siswa</div>
                            <div>
                                <button type="button" id="closeModalGenerate" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3 m-t-10">
                        <form v-on:submit.prevent="onGenerate">
                            <input type="hidden" class="form-control" id="" placeholder="Masukan Nama Siswa..." v-model="form.id" autocomplete="off">

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Kelas</label>
                                <div class="col-sm-9">
                                    <select class="form-control" v-model="generate_school_class">
                                        <option value="">Pilih Kelas</option>
                                        <option v-for="(item, index) in dataSchool.list_class" :key="index" :value="item">{{ item }}</option>
                                    </select>                                    

                                    <div class="alert alert-warning alert-dismissible fade show d-flex mt-2 text-center" role="alert">
                                        <strong><i class="fa fa-info-circle"></i></strong>
                                        <small class="m-l-10 mb-0">
                                            Silahkan pilih kelas terlebih dahulu untuk melakukan generate qr-code absensi
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary rounded btn-block" :disabled="waiting_generate">
                            <i class="fa fa-qrcode"></i> {{ waiting_generate ? 'Sedang melakukan generate...' : 'Generate & Download QR Code' }}</button>
                         </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'SchoolUsersInfo',
        metaInfo: {
            title: 'Data Nama Siswa',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                waiting          : false,
                waiting_generate : false,
                emptyState       : false,
                dataFetchSchool  : false,
                dataFetch        : false,
                dataList         : [],
                dataSchool       : [],
                school_class     : "",
                keywords         : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 30,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                form : {
                    id : '',
                    email : '',
                    fullname : '',
                    password : '',
                    school_class : ''
                },
                generate_school_class : ''
            }
        },
        created() {
            this.getSchool();
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/school-info';
                this.dataFetchSchool  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool    = false;                
                    this.dataSchool         = res.data.data;
                    this.getData();
                });
            },
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/school-users';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        school_class    : this.school_class,
                        keywords        : this.keywords,
                        page            : this.pagination.currentPage,
                        limit           : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/users-delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            editData: function(item) {
                this.form.id = item.id;
                this.form.email = item.email;
                this.form.fullname = item.fullname;
                this.form.school_class = item.school_class;
            },
            onSubmit: function() {
                if(this.form.email == '' || this.form.fullname == '' || this.form.school_class == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap lengkapi form terlebih dahulu",
                    });

                } else {
                    this.waiting = true;
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('fullname', this.form.fullname);
                    formData.append('email', this.form.email);
                    if(this.form.id == '') {
                        formData.append('password', this.form.password);
                    }
                    formData.append('school_class', this.form.school_class);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/users-save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    document.getElementById("closeModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            },
            onGenerate: function() {
                if(this.generate_school_class == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih kelas terlebih dahulu",
                    });

                } else {
                    this.waiting_generate = true;
                    let formData = new FormData();

                    formData.append('generate_school_class', this.generate_school_class);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/users-generate-qrcode', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting_generate = false;
                                    document.getElementById("closeModalGenerate").click();
                                    

                                    const zipFileUrl    = response.data.data;
                                    const link          = document.createElement('a');
                                    link.href           = zipFileUrl;
                                    link.target         = '_blank';
                                    document.body.appendChild(link);
                                    link.click();

                                    document.body.removeChild(link);                                    
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting_generate = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting_generate = false;
                        return;
                    });
                }
            }
        }
    }
</script>
