<template>
    <div>
        <div v-if="fetchOverview" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!fetchOverview" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:void(0)" onclick="window.history.back();"><i class="fa fa-arrow-left m-r-10"></i></a> Persiapan Tryout CBT</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Tryout</div>
                                <div class="font-weight-600">{{dataTryout.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Total Waktu</div>
                                <div class="font-weight-600">{{ dataTryout.timer }} Menit</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Total Soal</div>
                                <div class="font-weight-600">{{ dataTryout.total_questions }} Soal</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Total Siswa Terdaftar</div>
                                <div class="font-weight-600">{{ dataTryout.total_members }} Orang</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Download Nilai</div>
                                <div class="font-weight-600">
                                    <span v-if="fetchDownload">Harap tunggu...</span>
                                    <button class="btn btn-primary rounded" type="button" v-if="!fetchDownload" v-on:click="downloadScore">
                                        Download Nilai
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Kata kunci</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div v-if="dataFetch">
                            <p>Sedang mengambil data...</p>
                        </div>

                        <!-- <div class="table-responsive" style="max-height: 750px; overflow-y: auto;"> -->
                        <div v-if="!dataFetch">
                            <div v-if="dataList.length > 0" class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width:5%" class="text-center">No</th>
                                            <th>Data Siswa</th>
                                            <th class="text-center">Benar</th>
                                            <th class="text-center">Salah</th>
                                            <th class="text-center">Skor Akhir</th>
                                            <th class="text-center">Waktu Mengerjakan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in dataList" :key="index">
                                            <td class="text-center">
                                                {{ ++index }}
                                            </td>
                                            <td>
                                                {{ item.fullname }}<br/>
                                                <small>Email: <b>{{ item.email }}</b></small><br/>
                                                <small>Asal Sekolah: <b>{{ item.school_name }}</b></small><br/>
                                                <small>Kelas: <b>{{ item.school_class }}</b></small>
                                            </td>
                                            <td v-if="item.answer.length != '0'" class="text-center">
                                                {{ item.answer.total_correct }}
                                            </td>
                                            <td v-if="item.answer.length != '0'" class="text-center">
                                                {{ item.answer.total_wrong }}
                                            </td>
                                            <td v-if="item.answer.length != '0'" class="text-center">
                                                {{ item.answer.skor_akhir }}
                                            </td>
                                            <td v-if="item.answer.length != '0'" class="text-center">
                                                {{ item.answer.start_date }}
                                            </td>
                                            <td v-if="item.answer.length == '0'" colspan="4" class="text-center">
                                                Belum mengerjakan TryOut
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="emptyState">
                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminCBTMonitoring',
        metaInfo: {
            title: 'Monitoring Tryout CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                fetchOverview : false,
                dataFetch : false,
                dataTryout : [],
                dataList : [],
                emptyState : false,
                keywords : '',
                fetchDownload : false
            }
        },
        created() {
            this.getOverview();
        },
        methods: {
            getOverview: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/overview';
                
                this.fetchOverview  = true;                
                this.$http.get(uri, {
                    params : {
                        id : this.$route.params.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.fetchOverview  = false;
                        this.dataTryout     = res.data.data;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'cbt_tryout'
                                });
                            }
                        });                        
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/monitoring-users';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                        keywords    : this.keywords
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            downloadScore: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/monitoring-users-excel';
                
                this.fetchDownload  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                        keywords    : this.keywords
                    },
                    responseType: 'blob'
                }).then(response => {
                    this.fetchDownload = false
                    
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'HASIL CBT - '+this.dataTryout.title}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                    }
                });                
            }
        }
    }
</script>