<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10"><router-link :to="{ name : 'monitoring_psikotest_class_detail', params : {id : $route.params.id}, query:{school_id : $route.query.school_id, class: $route.query.class}}">
                                    <i class="fa fa-arrow-left m-r-10"></i></router-link> Biodata Peserta
                                </h5>
                                <div class="float-right" style="margin-top:-65px">
                                    <button type="button" class="btn btn-primary rounded" data-toggle="modal" data-target="#modalForm">Ubah Biodata</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-block mb-1 mt-2">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Nama Lengkap: </div>
                                    <div class="font-weight-600">{{dataDetail.biodata.fullname}}</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Jenis Kelamin:</div>
                                    <div class="font-weight-600" v-if="dataDetail.biodata.gender">
                                        {{dataDetail.biodata.gender == 'L' ? 'Laki - laki':'Perempuan'}}
                                    </div>
                                    <div class="font-weight-600" v-if="!dataDetail.biodata.gender">
                                        -
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3">Kelas:</div>
                                    <div class="font-weight-600">{{dataDetail.biodata.class ? dataDetail.biodata.class:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-block mb-1 mt-2">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">NISN:</div>
                                    <div class="font-weight-600">{{dataDetail.biodata.identity_number ? dataDetail.biodata.identity_number : '-'}}</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3">Rencana Pilihan Jurusan Setelah Lulus:</div>
                                    <div class="font-weight-600">{{dataDetail.biodata.department_plan ? dataDetail.biodata.department_plan : '-'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-header" style="margin-top:-10px">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">
                                    A. Hasil Tes Psikologis
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 table-responsive">
                            <div class="d-block mb-1 mt-2">
                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3">IQ:</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="font-weight-600" v-if="dataDetail.ist.category.name">
                                        <p>{{dataDetail.ist.iq}} ({{dataDetail.ist.category.name}})</p>
                                    </div>
                                    <div class="font-weight-600" v-if="!dataDetail.ist.category.name">
                                        <p>-</p>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3">Termasuk dalam kategori:</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="font-weight-600">
                                        <p v-if="dataDetail.disc.category.length > 0">{{dataDetail.disc.category}}</p>
                                        <p v-if="dataDetail.disc.category.length < 1">-</p>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3">Gaya Belajar:</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="font-weight-600" v-html="dataDetail.disc.analysis.learning_style"></div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3">Kepribadian:</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="font-weight-600" v-html="dataDetail.disc.analysis.personality"></div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3">Minat:</div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="font-weight-600" v-html="dataDetail.disc.analysis.passion"></div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3">Bakat:</div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="font-weight-600" v-html="dataDetail.disc.analysis.talent"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-header" style="margin-top:-10px">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">
                                    B. Tes Hasil Belajar
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 table-responsive">
                            <div class="table-responsive">
                                <table style="font-weight: bold;  font-size:11px; width:100%; border-collapse: collapse; margin-top:10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                                    <thead>
                                        <tr class="text-center">
                                            <th style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #92cddc;" colspan="3">KELOMPOK MIPA</th>
                                            <th style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #76923b;" colspan="3">KELOMPOK IPS</th>
                                            <th v-if="dataDetail.biodata.group_bahasa" style="border: 1px solid black; text-align: center; padding: 10px; background-color: #ccc0d9;" colspan="3">KELOMPOK BAHASA</th>
                                            <th v-if="dataDetail.biodata.group_agama" style="border: 1px solid black; text-align: center; padding: 10px; background-color: #c5d9c0;" colspan="3">KELOMPOK AGAMA</th>
                                            <th class="d-none" style="border: 1px solid black; text-align: center; padding: 10px; background-color: #d3d3d3;" rowspan="2">Aksi</th>
                                        </tr>
                                        <tr>
                                            <th style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #EFF5F5;">Mata Pelajaran</th>
                                            <th style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #EFF5F5;">SMT-1</th>
                                            <th style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #EFF5F5;">SMT-2</th>

                                            <th style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #EFF5F5;">Mata Pelajaran</th>
                                            <th style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #EFF5F5;">SMT-1</th>
                                            <th style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #EFF5F5;">SMT-2</th>

                                            <th v-if="dataDetail.biodata.group_bahasa" style="border: 1px solid black; text-align: center; padding: 10px; background-color: #EFF5F5;">Mata Pelajaran</th>
                                            <th v-if="dataDetail.biodata.group_bahasa" style="border: 1px solid black; text-align: center; padding: 10px; background-color: #EFF5F5;">SMT-1</th>
                                            <th v-if="dataDetail.biodata.group_bahasa" style="border: 1px solid black; text-align: center; padding: 10px; background-color: #EFF5F5;">SMT-2</th>

                                            <th v-if="dataDetail.biodata.group_agama" style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #EFF5F5;">Mata Pelajaran</th>
                                            <th v-if="dataDetail.biodata.group_agama" style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; background-color: #EFF5F5;">SMT-1</th>
                                            <th v-if="dataDetail.biodata.group_agama" style="border: 1px solid black; text-align: center; padding: 10px; background-color: #EFF5F5;">SMT-2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in listStudy" :key="index">
                                            <td style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:30px">
                                                <textarea disabled class="form-control" v-model="form.mipa_subject[index]" rows="2"></textarea>
                                            </td>
                                            <td style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:10%">
                                                <input disabled type="number" class="form-control" v-model="form.mipa_one[index]">
                                            </td>
                                            <td style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:10%">
                                                <input disabled type="number" class="form-control" v-model="form.mipa_two[index]">
                                            </td>
                                            <td style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:30px">
                                                <textarea disabled class="form-control" v-model="form.ips_subject[index]" rows="2"></textarea>
                                            </td>
                                            <td style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:10%">
                                                <input disabled type="number" class="form-control" v-model="form.ips_one[index]">
                                            </td>
                                            <td style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:10%">
                                                <input disabled type="number" class="form-control" v-model="form.ips_two[index]">
                                            </td>
                                            <td v-if="dataDetail.biodata.group_bahasa" style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:30px">
                                                <textarea disabled class="form-control" v-model="form.bahasa_subject[index]" rows="2"></textarea>
                                            </td>
                                            <td v-if="dataDetail.biodata.group_bahasa" style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:10%">
                                                <input disabled type="number" class="form-control" v-model="form.bahasa_one[index]">
                                            </td>
                                            <td v-if="dataDetail.biodata.group_bahasa" style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:10%">
                                                <input disabled type="number" class="form-control" v-model="form.bahasa_two[index]">
                                            </td>
                                            <td v-if="dataDetail.biodata.group_agama" style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:30px">
                                                <textarea disabled class="form-control" v-model="form.agama_subject[index]" rows="2"></textarea>
                                            </td>
                                            <td v-if="dataDetail.biodata.group_agama" style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:10%">
                                                <input disabled type="number" class="form-control" v-model="form.agama_one[index]">
                                            </td>
                                            <td v-if="dataDetail.biodata.group_agama" style="border: 1px solid black; text-align: center; padding: 10px; border-right: none; width:10%">
                                                <input disabled type="number" class="form-control" v-model="form.agama_two[index]">
                                            </td>
                                            <td class="d-none" style="border: 1px solid black; text-align: center; padding: 10px; width:5%">
                                                <button  v-if="index == listStudy - 1" type="button" class="btn btn-sm btn-danger rounded" v-on:click="deleteStudy(index)"><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                        <tr class="d-none">
                                            <td style="border: 1px solid black; text-align: left; padding: 10px;" colspan="10">
                                                <button type="button" class="btn btn-sm btn-outline-primary rounded mr-2" v-on:click="addStudy"><i class="fa fa-plus"></i> Tambah Data</button>
                                                <button v-if="listStudy > 0" type="button" class="btn btn-sm btn-primary rounded" v-on:click="saveStudy"><i class="fa fa-save"></i> Simpan Data</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-header" style="margin-top:-10px">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">
                                    C. Harapan Peserta didik dan Orangtua melalui hasil angket dan wawancara
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <textarea v-model="form.participant_expectations" class="form-control" rows="4" placeholder="Masukan data..."></textarea>
                            <br/>
                            <button type="button" class="btn btn-primary rounded" v-on:click="saveData"> <i class="fa fa-save"></i> Simpan</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-header" style="margin-top:-10px">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">
                                    D. Rekomendasi Guru Mata Pelajaran
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                             <textarea v-model="form.teacher_recommendation" class="form-control" rows="4" placeholder="Masukan data..."></textarea>
                            <br/>
                            <button type="button" class="btn btn-primary rounded" v-on:click="saveData"> <i class="fa fa-save"></i> Simpan</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-header" style="margin-top:-10px">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">
                                    E. Rekomendasi akhir guru BK terhadap Peserta didik
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12" v-if="dataDetail.department_recommendations">
                            <div v-if="dataDetail.error_recomendation == '1'">
                                <div>
                                    <div class="d-block mb-1 mt-2">
                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                            <div class="mr-3">Rekomendasi Jurusan : <b>{{dataDetail.department_recommendations}}</b> </div>
                                        </div>
                                    </div>
                                    <div class="d-block mb-1 mt-2" v-if="dataDetail.selected_list_subject">
                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                            <div class="mr-3">Mata pelajaran dengan nilai Rata - Rata tertinggi (Tes Hasil Belajar): <b>{{dataDetail.selected_list_subject}}</b> </div>
                                        </div>
                                    </div>

                                    <div class="d-block mb-1 mt-2">
                                        <div class="col-md-12">
                                            <div class="alert alert-warning text-center" role="alert">
                                                Rekomendasi mata pelajaran belum tersedia, karena data tes hasil belajar dan hasil tes minat belum terpenuhi.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="dataDetail.error_recomendation == '0'">
                                <div v-if="dataDetail.show_package_subject == 0">
                                    <div class="d-block mb-1 mt-2">
                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                            <div class="mr-3">Rekomendasi Jurusan : <b>{{dataDetail.department_recommendations}}</b> </div>
                                        </div>
                                    </div>

                                    <div class="d-block mb-1 mt-2">
                                        <div class="d-flex align-items-center justify-content-between pb-2">
                                            <div class="mr-3">Rekomendasi Mata Pelajaran :
                                                <ul class="mt-2">
                                                    <li v-for="(item, index) in dataDetail.study_recomendations" :key="index">{{item}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="dataDetail.show_package_subject == 1">
                                    
                                    <div v-if="dataDetail.package_subject_list.length < 1" class="d-block mb-1 mt-2">
                                        <div class="d-flex align-items-center justify-content-between pb-2">
                                            <div class="mr-3">Rekomendasi Mata Pelajaran:

                                                <div class="mt-2" v-if="dataDetail.study_recomendations[0] == 'null'">
                                                    <b><i>Saat ini rekomendasi mata pelajaran anda belum tersedia, harap hubungi admin untuk informasi lebih lanjut.</i></b>
                                                </div>

                                                <ul class="mt-2" v-if="dataDetail.study_recomendations[0] != 'null'">
                                                    <li v-for="(item, index) in dataDetail.study_recomendations" :key="index">{{item}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataDetail.package_subject_list.length > 0" class="d-block mb-1 mt-2">
                                        <div class="d-flex align-items-center justify-content-between pb-2">
                                            <div class="mr-3">Rekomendasi Mata Pelajaran :
                                                <ul class="mt-2">
                                                    <li v-for="(item, index) in dataDetail.package_subject_list" :key="index">{{item.name}} : {{ item.description }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        <div class="col-md-12" v-if="!dataDetail.department_recommendations">
                            <div class="alert alert-warning text-center" role="alert">
                                Rekomendasi akhir akan otomatis muncul jika <b>Hasil Tes Belajar</b> sudah diisi.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card card-hover-none mb-3">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center" v-if="!dataDetail.biodata.complete">
                                <div class="card-title d-flex mb-0"><i class="fa fa-info-o"></i>Saya sudah yakin dengan data yang telah dilengkapi</div>
                                <button type="button" class="btn btn-primary rounded" v-on:click="confirmComplete">
                                    <i class="fa fa-save"></i> Simpan Kelengkapan Data
                                </button>
                            </div>

                            <div class="d-flex justify-content-between align-items-center" v-if="dataDetail.biodata.complete">
                                <div class="card-title d-flex mb-0"><i class="fa fa-info-o"></i>Unduh Hasil Lembar Analisis</div>
                                <button
                                :disabled="isFetchResult"
                                class="btn btn-info rounded " type="button" v-on:click="downloadResult">
                                    <i v-if="isFetchResult" class="fa fa-spin fa-spinner"></i>
                                    <i v-if="!isFetchResult" class="fa fa-download"></i>
                                    {{ isFetchResult ? 'Loading...' : 'Unduh Lembar Analisis' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ubah Biodata Peserta</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">Nama Lengkap</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form_biodata.fullname" :class="{ 'is-invalid': submitted && $v.form_biodata.fullname.$error }">
                                        <div v-if="submitted && !$v.form_biodata.fullname.required" class="invalid-feedback">Nama wajib diisi!</div>
                                    </div>

                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">Jenis Kelamin</label>
                                        <select class="form-control" v-model="form_biodata.gender" :class="{ 'is-invalid': submitted && $v.form_biodata.gender.$error }">
                                            <option value="">Pilih Jenis Kelamin</option>
                                            <option value="L">Laki-laki</option>
                                            <option value="P">Perempuan</option>
                                        </select>
                                        <div v-if="submitted && !$v.form_biodata.gender.required" class="invalid-feedback d-block">
                                            Jenis kelamin wajib dipilih</div>
                                    </div>

                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">Kelas</label>
                                        <select class="form-control" v-model="form_biodata.class" :class="{ 'is-invalid': submitted && $v.form_biodata.class.$error }">
                                            <option value="">Pilih Kelas</option>
                                            <option v-for="(item, index) in list_class" :key="index" :value="item">{{item}}</option>
                                        </select>
                                        <div v-if="submitted && !$v.form_biodata.class.required" class="invalid-feedback d-block">
                                            Kelas wajib dipilih</div>
                                    </div>

                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">NISN</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form_biodata.identity_number" :class="{ 'is-invalid': submitted && $v.form_biodata.identity_number.$error }">
                                        <div v-if="submitted && !$v.form_biodata.identity_number.required" class="invalid-feedback">NISN wajib diisi!</div>
                                    </div>

                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">Rencana Pilihan Jurusan Setelah Lulus</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form_biodata.department_plan">
                                    </div>

                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Perubahan' }}</button>
                    </form>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Hasil Psikotest',
            titleTemplate: '%s - Griya Belajar'
        },
        validations: {
            form_biodata: {
                fullname: {
                    required
                },
                gender: {
                    required
                },
                class: {
                    required
                },
                identity_number: {
                    required
                },
            },
        },        
        data() {
            return {
                dataFetch            : true,
                isFetchResult   : false,
                dataDetail  : [],
                form : {
                    id      : "",
                    code    : "",
                    participant_expectations    : "",
                    teacher_recommendation      : "",
                    mipa_subject   : [],
                    mipa_one   : [],
                    mipa_two   : [],
                    ips_subject   : [],
                    ips_one   : [],
                    ips_two   : [],
                    bahasa_subject   : [],
                    bahasa_one   : [],
                    bahasa_two   : [],
                    agama_subject   : [],
                    agama_one   : [],
                    agama_two   : [],
                    complete : 0
                },
                listStudy : 0,
                form_biodata : {
                    fullname : '',
                    gender : '',
                    class : '',
                    identity_number : '',
                    department_plan : ''
                },
                list_class      : [],
                submitted       : false,
                waiting         : false
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring/detail-member-psikotest';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        psikotest_id    : this.$route.params.id,
                        user_id         : this.$route.params.user_id,
                        school_id       : this.$route.query.school_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;          
                        this.dataDetail = res.data.data;
                        this.list_class = this.dataDetail.list_class;
                        this.form.participant_expectations  = this.dataDetail.biodata.participant_expectations;
                        this.form.teacher_recommendation    = this.dataDetail.biodata.teacher_recommendation;
                        this.listStudy = res.data.data.total_study;

                        for (let index = 0; index < res.data.data.total_study; index++) {
                            if(typeof res.data.data.list_mipa[index] !== "undefined") {
                                this.form.mipa_subject[index] = res.data.data.list_mipa[index].category;
                                this.form.mipa_one[index] = res.data.data.list_mipa[index].periode_one;
                                this.form.mipa_two[index] = res.data.data.list_mipa[index].periode_two;
                            }

                            if(typeof res.data.data.list_ips[index] !== "undefined") {
                                this.form.ips_subject[index] = res.data.data.list_ips[index].category;
                                this.form.ips_one[index] = res.data.data.list_ips[index].periode_one;
                                this.form.ips_two[index] = res.data.data.list_ips[index].periode_two;
                            }

                            if(typeof res.data.data.list_bahasa[index] !== "undefined") {
                                this.form.bahasa_subject[index] = res.data.data.list_bahasa[index].category;
                                this.form.bahasa_one[index] = res.data.data.list_bahasa[index].periode_one;
                                this.form.bahasa_two[index] = res.data.data.list_bahasa[index].periode_two;
                            }

                            if(typeof res.data.data.list_agama[index] !== "undefined") {
                                this.form.agama_subject[index] = res.data.data.list_agama[index].category;
                                this.form.agama_one[index] = res.data.data.list_agama[index].periode_one;
                                this.form.agama_two[index] = res.data.data.list_agama[index].periode_two;
                            }

                        }

                        this.form_biodata.fullname = this.dataDetail.biodata.fullname;
                        this.form_biodata.gender = this.dataDetail.biodata.gender;
                        this.form_biodata.class = this.dataDetail.biodata.class;
                        this.form_biodata.identity_number = this.dataDetail.biodata.identity_number;
                        this.form_biodata.department_plan = this.dataDetail.biodata.department_plan;

                    } else {
                        this.$router.push({
                            name: 'monitoring_psikotest_detail',
                            params : {
                                id : this.$route.params.id
                            }
                        });
                    }
                });
            },
            saveData: function() {
                let formData = new FormData();

                formData.append('psikotest_id', this.$route.params.id);
                formData.append('user_id', this.$route.params.user_id);
                formData.append('participant_expectations', this.form.participant_expectations);
                formData.append('teacher_recommendation', this.form.teacher_recommendation);
                formData.append('complete', this.form.complete);

                this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/save-members-data', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.getData();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            confirmComplete: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Kelengkapan data',
                    text: 'Apakah kamu yakin dengan data yang telah dilengkapi',
                    confirmButtonText: 'Yakin',
                    showCancelButton: true,
                    cancelButtonText: 'Cek Kembali',
                    }).then((result) => {
                    if (result.value) {
                        this.form.complete = 1;
                        this.saveData();
                    }
                });
            },
            addStudy: function() {
                this.listStudy = this.listStudy+1; 
            },
            deleteStudy: function(index) {
                this.form.mipa_subject[index]   = '';
                this.form.mipa_one[index]       = '';
                this.form.mipa_two[index]       = '';
                this.form.ips_subject[index]    = '';
                this.form.ips_one[index]        = '';
                this.form.ips_two[index]        = '';
                this.form.bahasa_subject[index] = '';
                this.form.bahasa_one[index]     = '';
                this.form.bahasa_two[index]     = '';
                this.form.agama_subject[index] = '';
                this.form.agama_one[index]     = '';
                this.form.agama_two[index]     = '';
                this.listStudy = this.listStudy-1;
            },
            saveStudy: function() {
                let formData = new FormData();

                formData.append('psikotest_id', this.$route.params.id);
                formData.append('user_id', this.$route.params.user_id);
                formData.append('totalData', this.listStudy);
                formData.append('mipa_subject', JSON.stringify(this.form.mipa_subject));
                formData.append('mipa_one', JSON.stringify(this.form.mipa_one));
                formData.append('mipa_two', JSON.stringify(this.form.mipa_two));
                formData.append('mipa_two', JSON.stringify(this.form.mipa_two));
                formData.append('ips_subject', JSON.stringify(this.form.ips_subject));
                formData.append('ips_one', JSON.stringify(this.form.ips_one));
                formData.append('ips_two', JSON.stringify(this.form.ips_two));
                formData.append('bahasa_subject', JSON.stringify(this.form.bahasa_subject));
                formData.append('bahasa_one', JSON.stringify(this.form.bahasa_one));
                formData.append('bahasa_two', JSON.stringify(this.form.bahasa_two));
                formData.append('agama_subject', JSON.stringify(this.form.agama_subject));
                formData.append('agama_one', JSON.stringify(this.form.agama_one));
                formData.append('agama_two', JSON.stringify(this.form.agama_two));

                this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/save-members-study', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.listStudy = 0;
                                this.mipa_subject = [];
                                this.mipa_one = [];
                                this.mipa_two = [];
                                this.ips_subject = [];
                                this.ips_one = [];
                                this.ips_two = [];
                                this.bahasa_subject = [];
                                this.bahasa_one = [];
                                this.bahasa_two = [];
                                this.agama_subject = [];
                                this.agama_one = [];
                                this.agama_two = [];
                                this.getData();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            downloadResult() {
                this.isFetchResult = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring/download-result-psikotest', {
                    params : {
                        psikotest_id     : this.$route.params.id,
                        user_id          : this.$route.params.user_id,
                        school_id        : this.$route.query.school_id
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchResult = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Sertifikat gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Lembar Hasil Tes - '+this.dataDetail.biodata.fullname}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('psikotest_id', this.$route.params.id);
                    formData.append('user_id', this.$route.params.user_id);
                    formData.append('fullname', this.form_biodata.fullname);
                    formData.append('gender', this.form_biodata.gender);
                    formData.append('identity_number', this.form_biodata.identity_number);
                    formData.append('class', this.form_biodata.class);
                    formData.append('department_plan', this.form_biodata.department_plan);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/save-members-biodata', formData)
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    this.getData();
                                    document.getElementById("btnCloseModal").click();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });

                            this.waiting    = false;
                            this.submitted  = false;
                        }
                    })
                }
            }
        }
    }
</script>