<template>
    <div>
        <h5>Dashboard</h5>
        <hr />
        <div v-if="!userdata.fullname" class="d-block p-4 bg-primary-light rounded mb-4 griya-welcome-banner">
            <div class="d-flex align-items-center justify-content-between">
                <div class="w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-md m-b-2"></div>
                                <div class="sb-lg mt-2"></div>
                                <div class="sb-lg sb-thin mt-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="userdata.fullname" class="d-block p-4 bg-primary-light rounded griya-welcome-banner">
            <h4 class="m-t-10">Hallo {{userdata.fullname}}, Selamat datang di Griya Belajar!</h4>
            <div class="d-block h1 mb-2">Sudah siap belajar apa hari ini ?</div>
            <p class="">Jangan lupa semangat! Karena banyak latihan dan tryout yang masih menunggu untuk
                diselesaikan.</p>
        </div>

        <div v-if="dataFetch && !userdata.fullname">
            <p>Sedang mengambil data...</p>
        </div>

        <div v-if="!dataFetch  && userdata.fullname">
            <div v-if="userdata.type != 'proctor' && userdata.type != 'content'">
                <h5 class="m-t-30">Program dari Griya Belajar</h5>
                <hr />

                <div class="row">
                    <div v-for="(item, index) in dataList" :key="index" class="col-xl-4 col-md-4 col-lg-6">
                        <div class="card prod-p-card" :class="item.bg">
                            <router-link :to="{name : item.url}">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                        <h6 class="m-b-5 text-white">{{item.name}}</h6>
                                        <h3 class="m-b-0 text-white">{{item.total}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-archive text-white"></i>
                                    </div>
                                </div>
                            </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.type == 'proctor'">
                <h5 class="m-t-30">Statistik Data</h5>
                <hr />
                <div class="row">
                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-success">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                    <h6 class="m-b-5 text-white">Total Peserta</h6>
                                    <h3 class="m-b-0 text-white">{{dataProctor.total_user}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-users text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-info">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                <h6 class="m-b-5 text-white">Tryout SNBT</h6>
                                <h3 class="m-b-0 text-white">{{dataProctor.total_utbk}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-file text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-warning">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                <h6 class="m-b-5 text-white">Tryout Kedinasan</h6>
                                <h3 class="m-b-0 text-white">{{dataProctor.total_kedinasan}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-file text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-danger">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                <h6 class="m-b-5 text-white">Tryout AKM</h6>
                                <h3 class="m-b-0 text-white">{{dataProctor.total_akm}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-file text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Glide,
        GlideSlide
    } from 'vue-glide-js'
    import 'vue-glide-js/dist/vue-glide.css'
    import {
        mapState
    } from 'vuex'

    export default {
        name: 'Members',
        metaInfo: {
            title: 'Dashboard',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            [Glide.name]: Glide,
            [GlideSlide.name]: GlideSlide
        },
        data() {
            return {
                dataFetch   : false,
                dataList    : [],
                dataProctor : [],
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData');
        },
        computed: mapState(['userdata']),
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'dashboard/package';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataList = res.data.data;
                        this.getStatistic(res.data.type);
                    }
                    this.dataFetch = false;                
                });
            },
            getStatistic: function(type="") {
                if(type == 'proctor') {
                    let uri     = process.env.VUE_APP_APIHOST+'dashboard/statistic';
                    this.dataFetch  = true;
                    this.$http.get(uri).then(res => {
                        if(res.data.status) {
                            this.dataProctor = res.data.data;
                        }
                        this.dataFetch = false;                
                    });
                }
            }
        },
    }
</script>