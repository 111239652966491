<template>
    <div>
        <div v-if="dataFetchInfo">
            <p>Sedang memuat..</p>
        </div>
        <div v-if="!dataFetchInfo">
            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Informasi Jadwal</h5>
                                <div class="float-right" style="margin-top:-65px">
                                    <a href="javascript:void(0)" v-on:click="getInfo()" class="btn btn-primary rounded mr-2">Muat Ulang</a>
                                    <a href="javascript:void(0)" v-on:click="goBack()" class="btn btn-outline-primary rounded">Kembali</a>
                                </div>
                            </div>
                            <div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div class="d-flex align-items-center justify-content-between mb-3">
                                            <div><i class="fas fa-book m-r-5"></i> Kelas</div>
                                            <div class="font-weight-600">{{ dataInfo.school_class }}</div>
                                        </div>

                                        <div class="d-flex align-items-center justify-content-between mb-3">
                                            <div><i class="fas fa-book-open m-r-5"></i> Mata Pelajaran</div>
                                            <div class="font-weight-600">{{ dataInfo.subjects_name }}</div>
                                        </div>
                                        
                                        <div class="d-flex align-items-center justify-content-between mb-3">
                                            <div><i class="fas fa-clock m-r-5"></i> Waktu</div>
                                            <div class="font-weight-600">{{dataInfo.text_date}} | {{ dataInfo.start_time }} s/d {{ dataInfo.end_time }}</div>
                                        </div>

                                        <div class="d-flex align-items-center justify-content-between mb-0">
                                            <div><i class="fas fa-users m-r-5"></i> Total Siswa</div>
                                            <div class="font-weight-600">{{ dataInfo.total_members }} Siswa</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-block">
                                            <div class="d-flex align-items-center justify-content-between mb-0">
                                                <div><i class="fas fa-percentage m-r-5"></i> Persentase Kehadiran</div>
                                                <div class="font-weight-600">{{dataInfo.attendance_percentage}}% (Hadir)</div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-md-3" v-for="(item, index) in dataInfo.presence_counts" :key="index">
                                                    <div class="d-block p-3 rounded bg-light p-3 text-center">
                                                        <div class="d-block mb-2 font-weight-600">
                                                            <span v-if="index == 'H'">Hadir</span>
                                                            <span v-if="index == 'I'">Izin</span>
                                                            <span v-if="index == 'S'">Sakit</span>
                                                            <span v-if="index == 'A'">Alpha</span>
                                                            ({{ index }})
                                                        </div>
                                                        <div class="text-xlg" style="line-height:1;">{{ item }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-header" style="margin-top:-15px !important">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Data Siswa</h5>
                                <div class="float-right" style="margin-top:-65px">
                                    <a href="javascript:void(0)" v-on:click="toggleQRCode()" class="btn btn-primary rounded mr-2" data-toggle="modal" data-target="#modal_form_qrcode"><i class="fas fa-qrcode"></i> Tampilkan QR Code untuk absen siswa</a>
                                    <a href="javascript:void(0)" v-on:click="setPresenceAll()" class="btn btn-outline-primary rounded" ><i class="fas fa-check"></i> Hadirkan Semua Siswa</a>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Status</label>
                                        <select class="form-control" v-model="status" v-on:change="getData()">
                                            <option value="">Filter Status</option>
                                            <option value="H">Hadir</option>
                                            <option value="I">Izin</option>
                                            <option value="S">Sakit</option>
                                            <option value="A">Alpha / Tidak Hadir</option>
                                            <option value="X">Belum Absen</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Kata kunci</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getByKeywords">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div v-if="dataFetch">
                                        <p>Sedang mengambil data...</p>
                                    </div>

                                    <div v-if="!dataFetch" class="table-responsive">
                                        <div v-if="dataList.length > 0" >
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:5%" class="text-center">No</th>
                                                        <th style="width:25%">Siswa</th>
                                                        <th style="width:20%">Waktu Presensi</th>
                                                        <th style="width:5%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>
                                                            {{ item.fullname }}<br/>
                                                            <small>{{ item.email }}</small>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.id">
                                                                <span class="text-info font-weight-600" v-if="item.method == 'manually' || item.method == 'qrcode_teacher'">{{ item.clock_in }}</span>
                                                                <div v-if="item.method == 'qrcode_student'">
                                                                    <span>Jam Masuk: <b class="text-info">{{ item.clock_in }}</b></span><br/>
                                                                    <span>Jam Pulang: <b class="text-primary">{{ item.clock_out }}</b></span>
                                                                </div>
                                                            </span>
                                                            <span v-if="!item.id" :id="'text_presence_'+item.users_existing_id">Belum melakukan presensi</span>
                                                        </td>
                                                        <td>
                                                            <a v-for="(itemPresence, indexPresence) in dataInfo.presence_counts" :key="indexPresence" href="javascript:void(0)" class="btn btn-md rounded m-r-10" :class="item.presence_status == indexPresence ? 'btn-primary btn_presence_'+index:'btn-outline-primary btn_presence_'+index" :id="'btn_presence_'+index+'_'+indexPresence" v-on:click="setPresence(index, indexPresence, item.users_existing_id, item.id)">
                                                                {{ indexPresence }}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div v-if="emptyState">
                                            <empty-state></empty-state>
                                        </div>
                                    </div>


                                    <nav v-if="(!dataFetch && dataList.length > 0)" aria-label="navigation" class="m-t-20">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="modal_form_qrcode" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-lg modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px">QR Code Absensi</div>
                                <div>
                                    <button v-on:click="toggleQRCode()" type="button" id="closeModal" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        <div class="d-block m-t-10 text-center" >
                            <div class="alert alert-warning fade show mt-2 " role="alert">
                                <small>
                                    Silahkan tunjukan QR Code berikut ini kepada siswa untuk melakukan presensi.<br/>
                                    QR Code ini akan berubah setiap 10 detik
                                </small>
                            </div>
                            <div v-if="fetch_qrcode_schedule" class="mt-5 mb-5 text-muted">
                                <i class="fa fa-spin fa-spinner"></i>
                            </div>

                            <div v-if="!fetch_qrcode_schedule">
                                <img :src="qrcode_schedule" style="width: 60%; margin-top:-15px !important">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'ScheduleStudents',
        metaInfo: {
            title: 'Detail Jadwal Kelas',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                waiting         : false,
                emptyState      : false,
                dataFetch       : false,
                dataFetchInfo   : false,
                fetch_qrcode_schedule   : false,
                dataList        : [],
                dataInfo        : [],
                status          : "",
                keywords        : "",
                qrcode_schedule : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 50,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                intervalId  : null,
                isRunning   : false,                
            }
        },
        created() {
            this.getInfo();
        },
        mounted() {
            const closeButton = document.getElementById('closeModal');
            if (closeButton) {
                closeButton.addEventListener('click', this.closeModal);
            }
        },
        beforeUnmount() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            const closeButton = document.getElementById('closeModal');
            if (closeButton) {
                closeButton.removeEventListener('click', this.closeModal);
            }
        },
        methods: {
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getInfo: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/schedule-detail';
                this.dataFetchInfo  = true;                
                this.$http.get(uri,{
                    params : {
                        id  : this.$route.params.id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetchInfo  = false;                
                        this.dataInfo       = res.data.data;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                        });

                        this.goBack();
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/schedule-list-users';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id        : this.$route.params.id,
                        keywords  : this.keywords,
                        status    : this.status,
                        page      : this.pagination.currentPage,
                        limit     : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            goBack: function() {
                this.$router.push({ name : 'schedule_attendance_main'});
            },
            setPresence: function(index, presence_status, users_id, id) {
                let class_btn_presence = document.querySelectorAll('.btn_presence_'+index);

                class_btn_presence.forEach(class_btn_presence => {
                    class_btn_presence.classList.remove('btn-primary');
                    class_btn_presence.classList.add('btn-outline-primary');
                });                

                let id_btn_presence = document.getElementById('btn_presence_'+index+'_'+presence_status);
                id_btn_presence.classList.remove('btn-outline-primary');
                id_btn_presence.classList.add('btn-primary');

                const statusMap = {
                    'H': 'Hadir',
                    'I': 'Izin',
                    'S': 'Sakit',
                    'A': 'Alpha'
                };

                let text_presence_status = statusMap[presence_status];
                
                if(id) {
                    this.$swal({
                        icon: 'info',
                        title: 'Ubah Status Presensi',
                        html: 'Apakah kamu yakin ingin merubah status presensi siswa ini menjadi <b>'+text_presence_status+'</b>?',
                        confirmButtonText: 'Ya, Ubah',
                        showCancelButton: true,
                        cancelButtonText: 'Batal',
                    }).then((result) => {
                        if (result.value) {
                            this.saveAttendance(id, presence_status, users_id, 1);
                        }
                    });
                } else {
                    this.saveAttendance(id, presence_status, users_id, 0);
                }
            },
            saveAttendance: function(id, presence_status, users_id, params) {
                let formData = new FormData();

                if(id == null) {
                    id = '';
                }

                formData.append('id', id);
                formData.append('presence_status', presence_status);
                formData.append('attendance_schedule_id', this.$route.params.id);
                formData.append('users_id', users_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/save-attendance-users', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        if(params == 1) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });                            
                        }

                        this.dataInfo.attendance_percentage = response.data.data.attendance_percentage;
                        this.dataInfo.presence_counts       = response.data.data.presence_counts;

                        let text_presence = document.getElementById('text_presence_'+users_id);
                        text_presence.classList.add('text-info');
                        text_presence.classList.add('font-weight-600');
                        text_presence.textContent = response.data.data.date;
                        
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });

            },
            setPresenceAll: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Ubah Status Presensi',
                    html: 'Apakah kamu yakin ingin menghadirkan semua siswa?',
                    confirmButtonText: 'Ya, Hadirkan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.saveAttendanceAll();
                    }
                });
            },         
            saveAttendanceAll: function() {
                let formData = new FormData();

                formData.append('attendance_schedule_id', this.$route.params.id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/save-attendance-users-all', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.getInfo();
                            }
                        });                            
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });

            },
            setScheduleQRcode: function() {
                this.fetch_qrcode_schedule = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'admin/attendance/generate-schedule-qrcode', {
                    params : {
                        attendance_schedule_id  : this.$route.params.id,
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.qrcode_schedule = response.data.data;
                        this.fetch_qrcode_schedule  = false;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.fetch_qrcode_schedule  = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            toggleQRCode() {
                if (this.isRunning) {
                    clearInterval(this.intervalId);
                    this.isRunning = false;
                } else {
                    this.setScheduleQRcode();
                    this.intervalId = setInterval(() => {
                        this.setScheduleQRcode();
                    }, 10000);
                    this.isRunning = true;
                }
            },
            closeModal() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                    this.isRunning = false;
                }
            }
        }
    }
</script>
