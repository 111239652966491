<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Monitoring Tryout SNBT</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-3 d-none">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Program</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="program" v-on:change="getData">
                                        <option value="">Lihat Semua</option>
                                        <option value="saintek">Saintek</option>
                                        <option value="soshum">Soshum</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama dan email..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <!-- <th>Program</th> -->
                                                    <th>Nama Tryout</th>
                                                    <th class="text-center">Peserta Terdaftar</th>
                                                    <th style="width:5%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">{{++index}}</td>
                                                    <!-- <td>{{item.program}}</td> -->
                                                    <td>{{item.title}}</td>
                                                    <td class="text-center">{{item.total_users}} Orang</td>
                                                    <td>
                                                        <router-link :to="{name : 'monitoring_tryout_utbk_detail', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fa fa-edit"></i> Status Pengerjaan</router-link>
                                                        <router-link :to="{name : 'member_tryout_utbk_rank_school', params: { slug: item.code }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-outline-primary btn-sm rounded"><i class="fa fa-trophy"></i> Rangking Peserta</router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Monitoring Tryout SNBT',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                program     : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/list-utbk';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        program     : this.program,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            }
        }
    }
</script>
