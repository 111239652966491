<template>
    <div>
        <div v-if="dataFetchInfo" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchInfo" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{ name : 'monitoring_psikotest_class', params : {id : $route.params.id}}">
                                <i class="fa fa-arrow-left m-r-10"></i></router-link> Status Pengerjaan Peserta - {{ dataInfo.name }}
                            </h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kelas</div>
                                <div class="font-weight-600">{{ dataInfo.class }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3">Jumlah Peserta</div>
                                <div class="font-weight-600">{{ dataInfo.total }} Orang</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="d-block mb-1 mt-2">
                            <button class="btn btn-primary btn-block rounded" type="button" data-toggle="modal" data-target="#modalTemplateNilai">Download Template Nilai</button>
                            <button class="btn btn-outline-primary btn-block rounded" type="button" data-toggle="modal" data-target="#modalImportNilai">Import Nilai</button>
                            <button :disabled="waiting" class="btn btn-primary btn-block rounded" type="button" v-on:click="downloadTemplateData">
                                {{ waiting ? 'Harap tunggu ...' : 'Download Template Kelengkapan Data' }}
                            </button>
                            <button class="btn btn-outline-primary btn-block rounded" type="button" data-toggle="modal" data-target="#modalImportKelengkapan">Import Kelengkapan Data</button>
                            <button :disabled="waitingResult" class="btn btn-primary btn-block rounded" type="button" v-on:click="downloadResultPsikotest">
                                {{ waitingResult ? 'Harap tunggu ...' : 'Download Hasil Psikotest' }}
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="modalTemplateNilai" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-file"></i> Download Template Penilaian</div>
                                <div>
                                    <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>

                        <div class="d-block p-3">
                            <form v-on:submit.prevent="onSubmit">                            
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="exampleInputEmail1">Kelompok Mipa</label>
                                        <textarea class="form-control" rows="3" placeholder="Masukan nama mata pelajaran..." v-model="form.mipa"></textarea>
                                        <div v-if="submitted" class="invalid-feedback">Kelompok Mipa wajib diisi!</div>
                                        <small><i>Jika lebih dari 1 mata pelajaran, silahkan isi dengan menggunakan tanda "," (Koma). contoh: (Matematika, Fisika, Biologi)</i></small>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <label for="exampleInputEmail1">Kelompok IPS</label>
                                        <textarea class="form-control" rows="3" placeholder="Masukan nama mata pelajaran..." v-model="form.ips"></textarea>
                                        <div v-if="submitted && !$v.form.ips.required" class="invalid-feedback">Kelompok IPS wajib diisi!</div>    
                                        <small><i>Jika lebih dari 1 mata pelajaran, silahkan isi dengan menggunakan tanda "," (Koma). contoh: (Matematika, Fisika, Biologi)</i></small>
                                    </div>

                                    <div class="form-group col-md-12" v-if="dataInfo.group_bahasa">
                                        <label for="exampleInputEmail1">Kelompok Bahasa</label>
                                        <textarea class="form-control" rows="3" placeholder="Masukan nama mata pelajaran..." v-model="form.bahasa"></textarea>
                                        <small><i>Jika lebih dari 1 mata pelajaran, silahkan isi dengan menggunakan tanda "," (Koma). contoh: (Matematika, Fisika, Biologi)</i></small>
                                    </div>

                                    <div class="form-group col-md-12" v-if="dataInfo.group_agama">
                                        <label for="exampleInputEmail1">Kelompok Agama</label>
                                        <textarea class="form-control" rows="3" placeholder="Masukan nama mata pelajaran..." v-model="form.agama"></textarea>
                                        <small><i>Jika lebih dari 1 mata pelajaran, silahkan isi dengan menggunakan tanda "," (Koma). contoh: (Matematika, Fisika, Biologi)</i></small>
                                    </div>
                                </div>

                                <button type="submit" class="btn btn-primary m-r-10 rounded" :disabled="waiting">
                                <i class="fas fa-file-excel"></i> {{ waiting ? 'Harap tunggu ...' : 'Download Template Excel' }}</button>
                            </form>
                        </div>
                    </div> 
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="modalImportNilai" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-file"></i> Import Nilai</div>
                                <div>
                                    <button id="btnCloseModalImport" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>

                        <div class="d-block mt-3 p-3" v-if="!dataInfo.format_import">
                            <div class="alert alert-warning text-center">
                                Format penilaian belum diatur.
                            </div>
                        </div>

                        <div class="d-block p-3" v-if="dataInfo.format_import">
                            <form v-on:submit.prevent="onSubmitImportNilai">                            
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="exampleInputEmail1">File Template Penilaian</label>
                                        <input type="file" class="form-control" name="file" id="file" @change="previewFiles" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                        <small><i>Max upload : 5 MB</i></small>
                                    </div>
                                </div>

                                <button type="submit" class="btn btn-primary m-r-10 rounded" :disabled="waiting">
                                <i class="fa fa-upload"></i> {{ waiting ? 'Harap tunggu ...' : 'Upload Format' }}</button>
                            </form>
                        </div>
                    </div> 
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="modalImportKelengkapan" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-file"></i> Import Kelengkapan Data</div>
                                <div>
                                    <button id="btnCloseModalKelengkapan" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>

                        <div class="d-block p-3">
                            <form v-on:submit.prevent="onSubmitImportKelengkapan">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="exampleInputEmail1">File Template Kelengkapan Data</label>
                                        <input type="file" class="form-control" name="file" id="file" @change="previewFiles" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                        <small><i>Max upload : 5 MB</i></small>
                                    </div>
                                </div>

                                <button type="submit" class="btn btn-primary m-r-10 rounded" :disabled="waiting">
                                <i class="fa fa-upload"></i> {{ waiting ? 'Harap tunggu ...' : 'Upload Format' }}</button>
                            </form>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        
        <div v-if="!dataFetchInfo" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama dan email..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Nama</th>
                                                    <th class="text-center" style="width:15%">Status Mengerjakan</th>
                                                    <th class="text-center" style="width:15%">Status Kelengkapan Data</th>
                                                    <th class="text-center" style="width:15%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}                                                        
                                                    </td>
                                                    <td>
                                                        {{item.fullname}}<br/>
                                                        <small>Email : {{item.email}}</small>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.status_finished == 'notyet'" class="badge badge-danger">Belum Mengerjakan</span>
                                                        <span v-if="item.status_finished == 'ongoing'" class="badge badge-primary">Sedang Mengerjakan</span>
                                                        <span v-if="item.status_finished == 'finished'" class="badge badge-success">Sudah Mengerjakan</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.status_complete == 'notyet'" class="badge badge-dark">Belum Lengkap</span>
                                                        <span v-if="item.status_complete == 'complete'" class="badge badge-info">Sudah Lengkap</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <router-link
                                                            v-if="item.status_finished != 'notyet'"
                                                            :to="{name : 'monitoring_psikotest_detail_members', params : {id : item.id , user_id : item.user_id}, query:{school_id : $route.query.school_id, class: $route.query.class}}"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title=""
                                                            data-original-title="Edit"
                                                            type="button"
                                                            class="btn btn-primary btn-sm rounded m-r-10">
                                                                <i class="fa fa-edit"></i> Lihat Data
                                                        </router-link>
                                                        <button
                                                        v-if="item.status_complete == 'complete'"
                                                        :disabled="isFetchResult"
                                                        class="btn btn-info rounded " type="button" v-on:click="downloadResult(item.user_id, item.fullname)">
                                                            <i v-if="isFetchResult" class="fa fa-spin fa-spinner"></i>
                                                            <i v-if="!isFetchResult" class="fa fa-download"></i>
                                                            {{ isFetchResult ? 'Loading...' : 'Unduh Lembar Analisis' }}
                                                        </button>
                                                        <span v-if="item.status_finished == 'notyet'">-</span>                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Monitoring Psikotest',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState      : false,
                dataFetch       : false,
                dataFetchInfo   : false,
                isFetchResult   : false,
                dataList        : [],
                dataInfo    : {
                    format_import : {
                        id : ''
                    }
                },
                keywords    : "",
                school      : "",
                pagination  : {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                form : {
                    mipa    : '',
                    ips     : '',
                    bahasa  : '',
                    agama  : '',
                    file    : '',
                },
                submitted       : false,
                waiting         : false,
                waitingResult   : false,
            }
        },
        created() {
            this.getInfo();
        },
        validations: {
            form: {
                mipa: {
                    required
                },
                ips: {
                    required
                }
            }
        },
        methods: {
            getInfo: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring/info-member-class';
                
                this.dataFetchInfo  = true;                
                this.$http.get(uri,{
                    params : {
                        class           : this.$route.query.class,
                        school_id       : this.$route.query.school_id,
                        psikotest_id    : this.$route.params.id
                    }
                }).then(res => {
                    this.dataFetchInfo  = false;
                    if(res.data.status) {
                        this.dataInfo         = res.data.data;
                        this.dataFetchInfo    = false;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'monitoring_psikotest'
                                });
                            }
                        });                        
                    }

                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring/list-member-psikotest';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords        : this.keywords,
                        school_id       : this.$route.query.school_id,
                        class           : this.$route.query.class,
                        psikotest_id    : this.$route.params.id,
                        page            : this.pagination.currentPage,
                        limit           : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.pagination.totalData  = res.data.total_data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('class', this.$route.query.class);
                    formData.append('school_id', this.$route.query.school_id);
                    formData.append('psikotest_id', this.$route.params.id);
                    formData.append('mipa', this.form.mipa);
                    formData.append('ips', this.form.ips);
                    formData.append('bahasa', this.form.bahasa);
                    formData.append('agama', this.form.agama);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/download-format-nilai',
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `format-penilaian.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        this.getInfo();
                        this.waiting    = false;
                        this.submitted  = false;
                        document.getElementById("btnCloseModal").click();
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form.file = file;
                }
            },
            onSubmitImportNilai: function () {
                if(this.form.file == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap masukan file import",
                    });
                } else {
                    this.waiting    = true;
                    this.submitted  = true;

                    let formData = new FormData();
                    formData.append('class', this.$route.query.class);
                    formData.append('school_id', this.$route.query.school_id);
                    formData.append('psikotest_id', this.$route.params.id);
                    formData.append('file', this.form.file);
                    this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/import-format-nilai', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                        this.waiting    = false;
                                        this.submitted  = false;
                                        document.getElementById("btnCloseModalImport").click();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            },
            downloadTemplateData: function () {                    
                let formData = new FormData();

                formData.append('class', this.$route.query.class);
                formData.append('school_id', this.$route.query.school_id);
                formData.append('psikotest_id', this.$route.params.id);

                this.waiting    = true;
                this.submitted  = true;

                this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/download-format-data',
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `format-kelengkapan-data.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.waiting    = false;
                    this.submitted  = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            onSubmitImportKelengkapan: function () {
                if(this.form.file == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap masukan file import",
                    });
                } else {
                    this.waiting    = true;
                    this.submitted  = true;

                    let formData = new FormData();
                    formData.append('class', this.$route.query.class);
                    formData.append('school_id', this.$route.query.school_id);
                    formData.append('psikotest_id', this.$route.params.id);
                    formData.append('file', this.form.file);
                    this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/import-format-kelengkapan', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                        this.waiting    = false;
                                        this.submitted  = false;
                                        document.getElementById("btnCloseModalKelengkapan").click();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            },
            downloadResult(user_id, fullname) {
                this.isFetchResult = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring/download-result-psikotest', {
                    params : {
                        psikotest_id     : this.$route.params.id,
                        user_id          : user_id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchResult = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Sertifikat gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Lembar Hasil Tes - '+fullname}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            downloadResultPsikotest: function () {                    
                let formData = new FormData();

                formData.append('class', this.$route.query.class);
                formData.append('school_id', this.$route.query.school_id);
                formData.append('psikotest_id', this.$route.params.id);
                formData.append('tryout_name', this.dataInfo.name);

                this.waitingResult    = true;
                this.submitted  = true;

                this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/download-psikotest-users-result',
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `hasil-psikotest.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.waitingResult    = false;
                    this.submitted  = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waitingResult = false;
                    return;
                });
            }
        }
    }
</script>
