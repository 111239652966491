<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Daftar Jadwal Kelas</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <button type="button" v-on:click="startScan()" data-toggle="modal" data-target="#modal_scan_qrcode" class="btn btn-primary rounded"><i class="fas fa-camera"></i> Scan Barcode Absensi</button>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-between mb-3">
                                    <div class="d-flex mr-2" v-if="!dataFetch">
                                        <div class="d-block btn-similar text-center mt-2">
                                            <div class="text-muted-strong ng-binding ng-scope"><i class="fa fa-calendar mr-2"></i>Menampilkan Jadwal dari Hari <b>{{dataList.range_start}}</b> s/d <b>{{ dataList.range_end }}</b></div>
                                        </div>
                                    </div>

                                    <div class="d-flex mr-2" v-if="dataFetch">
                                        <div class="d-block btn-similar text-center mt-2">
                                            <div class="text-muted-strong ng-binding ng-scope">Sedang mengambil data...</div>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center btn-filter-group">
                                            <button v-on:click="changePage('prev')" type="button" class="btn btn-outline-primary rounded mr-2"><span class="fa fa-arrow-left m-0"></span></button>
                                            <button v-on:click="changePage('next')" type="button" class="btn btn-outline-primary rounded"><span class="fa fa-arrow-right m-0"></span></button>
                                        </div>
                                    </div>
                                </div>                                

                                <div v-if="!dataFetch" class="table-responsive">
                                    <table class="table table-bordered rounded">
                                        <thead>
                                            <tr>
                                                <th style="width:5%" class="text-center">Jam</th>
                                                <th v-for="(item, index) in dataList.range_text" :key="index" style="width:10%">
                                                    <span v-html="item.name"></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="emptyState">
                                            <tr>
                                                <td colspan="8">
                                                    <div class="text-center">
                                                        <img style="width:300px;max-width:100%" src="assets/images/empty-box.svg">
                                                        <h4 class="m-t-10">Tidak ada jadwal minggu ini</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="!emptyState">
                                            <tr v-for="(itemSchedule, indexSchedule) in dataList.schedule" :key="indexSchedule">
                                                <td class="text-center">{{ itemSchedule.start_time }} - {{ itemSchedule.end_time }}</td>
                                                <td class="text-center" v-for="(itemWeek, indexWeek) in itemSchedule.schedule_week" :key="indexWeek">
                                                    <div v-if="itemWeek.id">
                                                        <small>Mata Pelajaran:<br/>
                                                            <b>{{ itemWeek.subjects_code }}</b>
                                                        </small><br/>
                                                        <small>Kelas: <b>{{ itemWeek.school_class }}</b></small><br/>
                                                    </div>

                                                    <div v-if="!itemWeek.id">
                                                        <small class="text-center">Tidak ada jadwal</small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modal_scan_qrcode" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">Scan QRCode</div>
                            <div>
                                <button type="button" id="closeModal" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3 m-t-10">
                        <div>
                            <video ref="video" style="width: 100%; height: 300px; border: 1px solid #000;"></video>
                            <audio ref="audio" src="https://absensi.griyabelajar.com/assets/audio/beep.mp3"></audio>
                        </div>
                        <small>Catatan:
                            <ul class="mb-0">
                                <li>Silahkan arahkan kamera ke qrcode yang telah diberikan oleh guru.</li>
                                <li>Hubungi Admin jika terdapat kendala pada sistem.</li>
                            </ul>                                 
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BrowserMultiFormatReader } from '@zxing/library';
    export default {
        name: 'AttendanceScheduleMain',
        metaInfo: {
            title: 'Jadwal Kelas',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                waiting         : false,
                emptyState      : false,
                dataFetch       : false,
                dataList        : [],
                page            : 1,
                code            : '',
                codeReader      : null,
                dataSchedule    : []
            }
        },
        mounted() {
            this.codeReader = new BrowserMultiFormatReader();
        },        
        created() {
            this.getData();
        },
        beforeDestroy() {
            if (this.codeReader) {
                this.codeReader.reset();
            }
        },        
        methods: {
            startScan() {
                this.codeReader
                .decodeOnceFromVideoDevice(null, this.$refs.video)
                .then((result) => {
                    this.code = result.text;
                    this.$refs.audio.play();
                    this.checkSchedule(this.code);
                })
                .catch((err) => {
                    console.error(err);
                });
            },            
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'student/attendance/schedule-list';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        page      : this.page,
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;

                    if(this.dataList.is_empty == 1) {
                        this.emptyState = true;
                    } else {
                        this.emptyState = false;
                    }
                });
            },
            checkSchedule: function(signature) {
                let uri         = process.env.VUE_APP_APIHOST+'student/attendance/check';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        signature      : signature,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.$swal({
                            icon: 'info',
                            title: 'Informasi Jadwal',
                            html: 'Kelas: <b>'+res.data.data.school_class+'</b><br/>Mata Pelajaran: <b>'+res.data.data.subjects_name+'</b><br/>Jam Mulai: <b>'+res.data.data.start_time+'</b><br/>Jam Selesai: <b>'+res.data.data.end_time+'</b><br/><small>Silahkan klik tombol hadir dibawah ini untuk melakukan absensi kehadiran.</small>',
                            confirmButtonText: 'Hadir',
                            showCancelButton: true,
                            cancelButtonText: 'Batal',
                        }).then((result) => {
                            if (result.value) {
                                this.saveSchedule(signature);
                            } else {
                                this.startScan();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                        });

                        this.startScan();
                    }
                });
            },
            changePage: function(params=null) {
                if(params == 'prev') {
                    this.page = this.page-1;
                } else {
                    this.page = this.page+1;
                }

                this.getData();
            },
            saveSchedule: function(signature) {
                let uri         = process.env.VUE_APP_APIHOST+'student/attendance/save';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        signature      : signature,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Berhasil!',
                            html: res.data.message,
                            confirmButtonText: 'Selesai',
                            showCancelButton: false,
                            cancelButtonText: 'Batal',
                        }).then((result) => {
                            if (result.value) {
                                this.startScan();
                            } else {
                                this.startScan();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                        });
                        this.startScan();
                    }
                });
            },
        }
    }
</script>
